<template>
    <div class="system-container">
        <el-row>
            <el-button type="primary" size="small" @click="saveLayer">保存</el-button>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-row class="layer-item">
                    <label style="top:0;">图层类型:</label>
                    <el-select  v-model="layer" placeholder="请选择" filterable @change="setLayerStyle">
                        <el-option 
                            v-for="item in layerList" 
                            :key="item.layCode"
                            :label="item.layName"
                            :value="item.layCode"
                        ></el-option>
                    </el-select>
                </el-row>
                <el-row class="layer-item">
                    <label>填充颜色:</label>
                    <!-- <el-color-picker v-model="options.fillColor"></el-color-picker> -->
                    <el-color-picker
                        v-model="options.fillColor"
                        show-alpha
                        :predefine="predefineColors"></el-color-picker>
                </el-row>
                <!-- <el-row class="layer-item">
                    <label style="top:-2px;">填充色透明度:</label>
                    <el-input-number v-model="options.fillOpacity" :precision="1" :step="0.1" :max="1" size="small"></el-input-number>
                </el-row> -->
                <el-row class="layer-item">
                    <label>边框颜色:</label>
                    <!-- <el-color-picker v-model="options.outlineColor"></el-color-picker> -->
                    <el-color-picker
                        v-model="options.outlineColor"
                        show-alpha
                        :predefine="predefineColors"></el-color-picker>
                </el-row>
                <!-- <el-row class="layer-item">
                    <label style="top:-2px;">边框色透明度:</label>
                    <el-input-number v-model="options.outlineOpacity" :precision="1" :step="0.1" :max="1" size="small"></el-input-number>
                </el-row> -->
                <el-row class="layer-item">
                    <label>边框宽度:</label>
                    <el-slider class="layer-slider" v-model="options.outlineWidth" :max="10" :min="1"></el-slider>
                </el-row>
                <el-row class="layer-item">
                    <label>边框类型:</label>
                    <el-radio v-model="options.outlineType" v-for="item in outlineTypeList" :key="item.id" :label="item.type">{{item.name}}</el-radio>
                </el-row>
            </el-col>
            <el-col :span="12" style="position: relative;">
                <div class="comp-preview">
                    <div>预览：</div>
                    <div class="comp-preview-scope">
                        <div class="comp-preview-demo" ref="previewStyle"></div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import api from "@/api/index.js";
export default {
    name: "layerMaintain",
    data(){
        return {
            outlineTypeList: [
                {
                    id: 1,
                    type: "line",
                    name: "实线"
                },
                {
                    id: 2,
                    type: "dash",
                    name: "虚线"
                }
            ],
            layerList: [],
            cusNo: "",
            layer: "",
            options: {
                fillColor: "",
                fillOpacity: 0,
                outlineColor: "",
                outlineOpacity: 0,
                outlineType: "",
                outlineWidth: 0
            },
            predefineColors: [
                '#ff4500',
                '#ff8c00',
                '#ffd700',
                '#90ee90',
                '#00ced1',
                '#1e90ff',
                '#c71585',
                'rgba(255, 69, 0, 0.68)',
                'rgb(255, 120, 0)'
            ]
        }
    },
    mounted(){
        this.cusNo = sessionStorage.cusNo;
        this.initPage();
    },
    methods: {
        initPage() {
            api.mapStyle({
                cusNo: this.cusNo
            }).then(res => {
                if(res.code == 200) {
                    this.layerList = res.data;
                    let defaultLayer = this.layerList[0];
                    this.layer = defaultLayer.layCode;
                    this.options = this.initNullStyle(defaultLayer);
                }
            })
        },
        saveLayer() {
            this.options.cusNo = this.cusNo;
            api.saveMapStyle(this.options).then(res => {
                if(res.code == 200) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    this.options = this.initNullStyle(res.data);
                    sessionStorage.layerList = JSON.stringify(this.layerList);
                } else {
                    this.$message({
                        message: "保存失败",
                        type: "warning"
                    });
                }
            })
            
        },
        // 格式化透明度数据
        formatTooltip(val) {
            return val / 10;
        },
        //设置预览样式
        setPreviewStyle(style) {
            let preview = this.$refs.previewStyle;
            preview.setAttribute("style",
                `background:${style.fillColor};
                border: ${style.outlineWidth || 1}px ${style.outlineType!="line" ? "dashed" : "solid"} ${style.outlineColor};
                transform: translate(${33-style.outlineWidth}%, ${27-style.outlineWidth}%);`);
            
        },
         // opacity: ${style.fillOpacity || 1};
        //
        setLayerStyle(val) {
            for(let i = 0; i < this.layerList.length; i++) {
                let item = this.layerList[i];
                if(item.layCode == val) {
                    this.options = this.initNullStyle(item);
                    break;
                }
            }
        },
        //给图层样式赋一个初始值状态，当值都为空的时候
        initNullStyle(val) {
            //填充色透明度
            if(!val.fillOpacity) {
                val.fillOpacity = 1;
            } else {
                val.fillOpacity = Number(val.fillOpacity);
            }
            //边框透明度
            if(!val.outlineOpacity) {
                val.outlineOpacity = 1;
            } else {
                val.outlineOpacity = Number(val.outlineOpacity);
            }
            if(!val.outlineWidth) {
                val.outlineWidth = 1;
            } else {
                val.outlineWidth = Number(val.outlineWidth);
            }
            if(!val.outlineType) {
                val.outlineType = "line";
            }
            return val;
        }
    },
    watch: {
        options: {
            handler(new_value,old_value){
                this.setPreviewStyle(new_value);
            },
            deep: true
        }
    }
}
</script>
<style>
.system-container {
    font-size: 13px;
    padding: 10px 30px;
}
.layer-item {
    margin-top: 20px;
}
.layer-item label {
    display: inline-block;
    width: 90px;
    font-size: 12px;
    position: relative;
    top: -15px;
}
.layer-item .layer-slider {
    width: 30%;
    display: inline-block;
    margin-left: 10px;
}
.comp-preview-scope {
    width: 100px;
    height: 100px;
    /* border: 1px solid #ddd; */
    margin-top: 10px;
    background: url("../../assets/images/map.png") no-repeat;
    background-size: 100%;
}
.comp-preview-demo {
    width: 50px;
    height: 50px;
}
</style>
